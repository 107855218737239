import React from 'react';
import { Link, graphql } from 'gatsby';
import { css } from '@emotion/core';
import { Typography } from '@material-ui/core';
import { MdxArticle } from '../types/Article';
import { Category } from '../types/Category';
import { SiteMetadata } from '../types/SiteMetadata';
import Content from '../layouts/main';
import styled from '../styled';
import { SEO } from '../components/SEO';
import { PostLink } from '../components/PostLink';

type PageQueryData = {
  site: { siteMetadata: SiteMetadata };
  categoryYaml: Category;
  allMdx: { edges: { node: MdxArticle }[] };
};

export const pageQuery = graphql`
  query($categoryId: String) {
    categoryYaml(id: { eq: $categoryId }) {
      name
      description
      slug
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          id
          frontmatter {
            title
            summary
            category
            image
            date(formatString: "MMMM DD, YYYY")
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;

const PostContainer = styled('div')`
  ${({ theme }) => css`
    max-width: 1200px;
    padding: ${theme.spacing(2)}px;
    margin: ${theme.spacing(4)}px auto;
  `};
`;

const Hero = styled('div')`
  padding: 120px 16px 96px;
  color: white;
  background-color: #1c2126;
  text-align: center;

  a {
    display: inline-block;
    margin-top: 36px;
    font-weight: 700;
  }

  @media (max-width: 720px) {
    padding: 50px 16px 16px;
  }
`;

const H1 = styled('h1')`
  font-size: 60px;
  font-weight: 700px;
  text-align: center;
  line-height: 60px;

  @media (max-width: 720px) {
    font-size: 36px;
  }
`;

const Grid = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: ${(p) => p.theme.spacing(4)}px;
  grid-row-gap: ${(p) => p.theme.spacing(4)}px;

  & > div {
    margin-bottom: 12px;
  }

  @media (max-width: 720px) {
    grid-template-columns: 1fr;
  }
`;

export default function Template({ data }: { data: PageQueryData }) {
  const { categoryYaml: category, allMdx } = data;
  const PostsInCategory = allMdx.edges
    .filter(
      (post) =>
        post.node.frontmatter.category === category.slug &&
        !!post.node.frontmatter.date
    )
    .map((edge) => (
      <div key={edge.node.fields.slug}>
        <PostLink post={edge.node} />
      </div>
    ));

  return (
    <Content>
      <>
        <SEO
          title={category.name}
          description={category.description}
          pathname={`/category/${category.slug}/`}
        />
        <Hero>
          <H1>{category.name}</H1>
          <div style={{ maxWidth: '800px', margin: '0 auto' }}>
            <Typography variant="h6" component="p">
              {category.description}
            </Typography>
          </div>
          <Link to="/blog/">← Back to the blog</Link>
        </Hero>
        <PostContainer>
          <Grid>{PostsInCategory}</Grid>
        </PostContainer>
      </>
    </Content>
  );
}
